<script lang="js">
import { mapState } from "vuex";
import ResultTable from './ResultTable.vue'
import { http } from "@/http/http";
import dayjs from "dayjs";
import {
  mdiMenu,
  mdiPen,
  mdiCalendar,
  mdiPlaneCar,
  mdiCar,
  mdiFilter,
  mdiRefresh,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiTable,
} from "@mdi/js";

export default {
  name: "report-inbox",
  components: {
    // ReportInbox,
    // ReportTerkirim,
    // ReportEksternal,
    // ReportDisposition,
    ResultTable
  },
  data: () => ({
    reportKind: null,
    tabNum: 0,
    isFullScreen:false,
    modal1: false,
    modal2: false,
    // today : dayjs().format('YYYY-MM-DD'),
    // sevenDaysLater : dayjs().add(7, 'day').format('YYYY-MM-DD'),
    // dates: [this.today, this.sevenDaysLater],
    listBagian: [],
    listReport:[],
    iconMenu: mdiMenu,
    iconEdit: mdiPen,
    iconCalendar: mdiCalendar,
    iconPlaneCar:mdiPlaneCar,
    iconCar:mdiCar,
    iconSubmit:mdiFilter,
    iconRefresh:mdiRefresh,
    iconFullScreen:mdiFullscreen,
    iconShrinkScreen: mdiFullscreenExit,
    iconTable:mdiTable,
    overlay: false,
    bagianLoading: false,
    submitProcessing:false,
    form: {
      dates: [dayjs().format('YYYY-MM-DD'), dayjs().add(7, 'day').format('YYYY-MM-DD')],
      date2: new Date().toISOString().substr(0, 7),
      jenis_perjalanan:'',
      bagian_inisiator: '',
      bagian_pembuat:'',
      transport:'',
      transport_di_lokasi:'',
      nama_karyawan: '',
      keperluan_dinas: '',
      order_by:'nomor',
      order_dir:'asc'
    },
    jenisPerjalanan: [
      { id: 1, value: "dalam_wilayah", title: "Dalam Wilayah" },
      { id: 2, value: "luar_wilayah", title: "Luar Wilayah" },
      { id: 3, value: "luar_negeri", title: "Luar Negeri" },
    ],
    transportDiTujuan: [
      { id: 1, value: "rental", title: "Rental" },
      { id: 2, value: "online", title: "Online" },
      { id: 3, value: "tidak_ada", title: "Tidak Ada" },
      { id: 4, value: "pribadi", title: "Kendaraan Pribadi" },
    ],
    transportList: [
      "Pesawat Terbang (Company Paid)",
      "Kereta Api (Company Paid)",
      "Bus (Company Paid)",
      "Kapal Laut (Company Paid)",
      "Mobil (Company Paid)",
      "Booking Mandiri (Reimburse)",
      "Kendaraan Pribadi (Reimburse)",
    ],
  }),
  computed: {
    ...mapState("user", ["currentUser"]),
    reportList() {
      if (!this.currentUser) return [];
      if (this.currentUser.kode_bagian === "BAG237") {
        return [
          "Surat Masuk Eksternal",
          "Memo Masuk",
          "Memo Terkirim",
          "Disposisi Atasan",
        ];
      }
      return ["Memo Masuk", "Memo Terkirim", "Disposisi Atasan"];
    },
    dateRangeText() {
      return this.form.dates.join(' ~ ')
    },
    modeTanggal(){
      if (this.tabNum === 0) return 'spesifik';
      return 'bulanan'
    }
  },
  created() {
    this.getAllBagian();
  },
  methods: {
    toggleFullScreen(){
      this.isFullScreen = !this.isFullScreen
      const myDiv = document.getElementById('myReportContainer');
      const myParentDiv = document.getElementById('myReportParentContainer');
      if (this.isFullScreen === true) {
        document.body.appendChild(myDiv);
      } else {
        myParentDiv.appendChild(myDiv);
      }
    },
    save() {
      this.modal1 = false;
      this.modal2 = false;
    },
    async getAllBagian() {
      if (this.bagianLoading === true) return;
      this.bagianLoading = true;
      try {
        console.log('get bagian list for report')
        this.overlay = true;
        const response = await http.get(
          `src/list-bagian?searchkey=&for_report=1&selected_bagian=`
        );
        if (response) {
          this.listBagian = response;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.overlay = false;
        this.bagianLoading = false;
      }
    },
    async getReport(){
      if (this.submitProcessing) return;
      let [tglMulai, tglSelesai] = this.form.dates;

      if (this.modeTanggal ==='bulanan') tglMulai = this.form.date2

      this.submitProcessing = true
      this.listReport = []
      try {
        const response = await http.get(
          `report/sppd?mode_tanggal=${this.modeTanggal}&tanggal_mulai=${tglMulai}&tanggal_selesai=${tglSelesai?tglSelesai:tglMulai}&jenis_perjalanan=${this.form.jenis_perjalanan??''}&bagian_inisiator=${this.form.bagian_inisiator?this.form.bagian_inisiator.id:''}&bagian_pembuat=${this.form.bagian_pembuat?this.form.bagian_pembuat.id:''}&transport=${this.form.transport??''}&transport_di_lokasi=${this.form.transport_di_lokasi??''}&nama_karyawan=${this.form.nama_karyawan??''}&keperluan_dinas=${this.form.keperluan_dinas??''}&order_by=${this.form.order_by ? this.form.order_by : 'nomor'}&order_dir=${this.form.order_dir ? this.form.order_dir : 'asc'}&action=''`
        );
        if (response) {
           this.listReport = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {

        this.submitProcessing = false
      }
    },
    async getExcel() {
      if (this.submitProcessing) return;
      let [tglMulai, tglSelesai] = this.form.dates;

      if (this.modeTanggal ==='bulanan') tglMulai = this.form.date2

      this.submitProcessing = true

      http
        .get(
          `report/sppd?mode_tanggal=${this.modeTanggal}&tanggal_mulai=${tglMulai}&tanggal_selesai=${tglSelesai?tglSelesai:tglMulai}&jenis_perjalanan=${this.form.jenis_perjalanan??''}&bagian_inisiator=${this.form.bagian_inisiator?this.form.bagian_inisiator.id:''}&bagian_pembuat=${this.form.bagian_pembuat?this.form.bagian_pembuat.id:''}&transport=${this.form.transport??''}&transport_di_lokasi=${this.form.transport_di_lokasi??''}&nama_karyawan=${this.form.nama_karyawan??''}&keperluan_dinas=${this.form.keperluan_dinas??''}&order_by=${this.form.order_by ? this.form.order_by : 'nomor'}&order_dir=${this.form.order_dir ? this.form.order_dir : 'asc'}&action=export_to_excel`,
          { responseType: "blob" }
        )
        .then((response) => {
          if (response) {
            let startDate = dayjs(tglMulai).format("DD_MM_YYYY");
            let endDate = dayjs(tglSelesai).format("DD_MM_YYYY");
            let fileName = 'Laporan_SPPD_'+ startDate + '_' +endDate;
            if (this.modeTanggal ==='bulanan') {
              let startDate = dayjs(tglMulai).format("MM_YYYY");
              fileName = 'Laporan_SPPD_Bulanan_'+ startDate
            }

            // this.listReport = response.data;
            // this.loading = false;
            const url = URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
           link.setAttribute("download", fileName+'.xlsx');
            document.body.appendChild(link);
            this.submitProcessing = false
            link.click();
          }
        });
    },

    resetForm(){
      this.listReport = [];
      this.form = {
        dates: [dayjs().format('YYYY-MM-DD'), dayjs().add(7, 'day').format('YYYY-MM-DD')],
        date2: new Date().toISOString().substr(0, 7),
        jenis_perjalanan:'',
        bagian_inisiator: '',
        bagian_pembuat:'',
        transport:'',
        transport_di_lokasi:'',
        nama_karyawan: '',
        keperluan_dinas: '',
      }
    },
    changeOrder(params){
      this.form.order_by = params.orderBy
      this.form.order_dir = params.orderDir
      this.getReport()
    },

  }
};
</script>

<template>
  <div :class="{ full__screen: isFullScreen, base__card: !isFullScreen }">
    <div class="full__screen-content">
      <v-card-text class="">
        <v-row>
          <v-col cols="12" style="position: relative">
            <h3 class="title text--darken-2 font-weight-regular">
              {{
                `${$t("card.report")} ${reportKind ? "- " + reportKind : ""}`
              }}
            </h3>
            <h6 class="subtitle-2 font-weight-light mb-2">
              {{
                $t(
                  "label.select report type year and month must be set before click filter button"
                )
              }}
            </h6>
            <div style="position: absolute; top: 1rem; right: 1rem">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="toggleFullScreen"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon v-if="isFullScreen">{{ iconShrinkScreen }}</v-icon>
                    <v-icon v-else>{{ iconFullScreen }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.toggle full screen") }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>

        <v-card-text>
          <v-tabs v-model="tabNum">
            <v-tab>{{ $t("label.specifid date") }}</v-tab>
            <v-tab>{{ $t("label.flexible date") }}</v-tab>
          </v-tabs>
          <v-row style="margin-top: 1rem">
            <v-row justify="start" class="mt-1">
              <v-col
                cols="12"
                lg="3"
                v-show="tabNum === 0"
                class="tight-padding"
              >
                <v-dialog
                  ref="dialog1"
                  v-model="modal1"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.dates"
                      label="Pilih Rentang Tanggal"
                      :prepend-icon="iconCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.dates"
                    range
                    :first-day-of-week="1"
                    locale="id-id"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="save()"> OK </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                lg="3"
                v-show="tabNum === 1"
                class="tight-padding"
              >
                <v-dialog
                  ref="dialog2"
                  v-model="modal2"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date2"
                      label="Pilih Bulan"
                      :prepend-icon="iconCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date2"
                    type="month"
                    scrollable
                    :first-day-of-week="1"
                    locale="id-id"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="save()"> OK </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" lg="3" class="tight-padding">
                <v-autocomplete
                  :data-hint="$t('introjs.this is jenis perjalanan selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-model="form.jenis_perjalanan"
                  v-bind:items="jenisPerjalanan"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  auto-select-first
                  hide-details="true"
                  item-text="title"
                  item-value="value"
                  clearable
                  :prepend-icon="iconMenu"
                  label="Jenis Perjalanan"
                  placeholder="Jenis Perjalanan"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.title }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6" class="tight-padding">
                <v-autocomplete
                  :data-hint="$t('introjs.this is initiator selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  clearable
                  return-object
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  id="pembuatInput"
                  v-model="form.bagian_pembuat"
                  :items="listBagian"
                  hide-no-data
                  hide-selected
                  auto-select-first
                  hide-details="true"
                  item-text="nama_bagian"
                  item-value="inisial_bagian"
                  :prepend-icon="iconMenu"
                  :label="$t('label.sppd creator')"
                  :placeholder="$t('label.sppd creator')"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.nama_bagian }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6" class="tight-padding">
                <v-autocomplete
                  :data-hint="$t('introjs.this is initiator selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  clearable
                  return-object
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  id="inisiatorInput"
                  v-model="form.bagian_inisiator"
                  :items="listBagian"
                  hide-no-data
                  hide-selected
                  auto-select-first
                  hide-details="true"
                  item-text="nama_bagian"
                  item-value="inisial_bagian"
                  :prepend-icon="iconMenu"
                  :label="$t('label.initiator department')"
                  :placeholder="$t('label.initiator department')"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.nama_bagian }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6" class="tight-padding">
                <v-combobox
                  :prepend-icon="iconPlaneCar"
                  clearable
                  hide-selected
                  :label="$t('label.transportation')"
                  v-model="form.transport"
                  :items="transportList"
                  :data-hint="$t('introjs.this is transport selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                ></v-combobox>
              </v-col>
              <v-col cols="12" lg="6" class="tight-padding">
                <v-autocomplete
                  :data-hint="$t('introjs.this is on site transport selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-model="form.transport_di_lokasi"
                  v-bind:items="transportDiTujuan"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  auto-select-first
                  hide-details="true"
                  item-text="title"
                  item-value="value"
                  clearable
                  :prepend-icon="iconCar"
                  label="Kendaraan Di Lokasi Dinas"
                  placeholder="Kendaraan Di Lokasi Dinas"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.title }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="3" class="tight-padding">
                <v-text-field
                  :prepend-icon="iconEdit"
                  label="Nama Karyawan"
                  v-model="form.nama_karyawan"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3">
                <v-text-field
                  :prepend-icon="iconEdit"
                  label="keperluan Dinas"
                  v-model="form.keperluan_dinas"
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="button-container">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind:data-hint="
                      $t('introjs.this-button-for-submit-action')
                    "
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-bind:loading="submitProcessing"
                    v-bind:disabled="submitProcessing"
                    color="blue"
                    class="ma-2 white--text"
                    @click="getReport()"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon dark class="mr-2">
                      {{ iconSubmit }}
                    </v-icon>
                    {{ $t("button.get data") }}
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.get data") }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="listReport && listReport.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind:data-hint="
                      $t('introjs.this-button-for-submit-action')
                    "
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-bind:loading="submitProcessing"
                    v-bind:disabled="submitProcessing"
                    color="green"
                    class="ma-2 white--text"
                    @click="getExcel()"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon dark class="mr-2">
                      {{ iconTable }}
                    </v-icon>
                    {{ $t("button.download excel") }}
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.get data") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind:data-hint="
                      $t('introjs.this-button-for-submit-action')
                    "
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-bind:loading="submitProcessing"
                    v-bind:disabled="submitProcessing"
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="resetForm()"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon dark class="mr-2">
                      {{ iconRefresh }}
                    </v-icon>
                    {{ $t("button.reset") }}
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.reset form") }}</span>
              </v-tooltip>
            </div>
            <div style="position: relative; padding: 1rem; width: 100%">
              <ResultTable
                :items="listReport"
                @changeOrderColumn="changeOrder"
              />
            </div>
          </v-row>
        </v-card-text>
      </v-card-text>
    </div>
  </div>
</template>
<style lang="css" scoped>
.p-3 {
  padding: 0.8rem;
}
.base__card {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.full__screen {
  /*display: none; /* Hidden by default */
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with transparency */
  z-index: 10; /* Ensure modal appears above other content */
  overflow-y: auto; /* Allow modal to scroll if it overflows the screen */
}

.full__screen-content {
  background-color: #fff;
  margin: 5px auto;
  padding: 0px;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto; /* Ensure the content flows dynamically */
}

.button-container {
  width: 100%;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.tight-padding {
  padding: 0 0.4em;
}
.v-tabs {
  margin: 0px -10px;
}
</style>
