<script lang="js">
import { http } from "@/http/http";
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiUnfoldMoreHorizontal,
} from "@mdi/js";

export default {
  name: "report-table",
  data: () => ({
    lastOrderBy: 'nomor',
    overlay: false,
    iconUpDown: mdiUnfoldMoreHorizontal,
    orderDir: 'asc',
  }),
  props: {
    items: {
      type: [Array, Object],
    },
  },
  computed: {
    sortIcon() {
      if (this.orderDir === 'asc') return mdiChevronUp;
      return mdiChevronDown;
    }
  },
  methods: {
    changeOrder(orderBy) {
      if (this.lastOrderBy === orderBy) {
        this.orderDir = this.orderDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.orderDir = 'asc'
        this.lastOrderBy = orderBy
      }
      this.$emit("changeOrderColumn", { orderBy: orderBy, orderDir: this.orderDir });
    },
    downloadFile(tipe, memoId, attachmentId, namaFile, fileType) {
      if (!tipe || !memoId) return
      if (tipe === 'lampiran' && attachmentId === '') return

      const options = {
        responseType: "blob",
      };

      this.overlay = true;

      let link = `/memo/myrevision/${memoId}/getpdf`

      if (tipe === 'lampiran') {
        link = `/memo/${memoId}/attachments/${attachmentId}`
      }
      http
        .get(link, options)
        .then((response) => {
          if (response.status === 500) return this.overlay = false
          if (response) {
            if (tipe !== 'lampiran') {
              var file = new Blob([response], { type: "application/pdf" });
              var url = URL.createObjectURL(file);
              window.open(url, "", "width=800,height=768");
            } else {
              let dfileURL = window.URL.createObjectURL(new Blob([response]));
              let dfileLink = document.createElement("a");
              dfileLink.href = dfileURL;
              dfileLink.setAttribute("download", namaFile);
              document.body.appendChild(dfileLink);
              this.overlay = false
              dfileLink.click();
              if (fileType ==='application/pdf') {
                file = new Blob([response], { type: "application/pdf" });
                url = URL.createObjectURL(file);
                window.open(url, "", "width=800,height=768");
              }
            }
            this.overlay = false
          }
        });
    }

  }
}
</script>
<template>
  <div class="p-4 sppd-table-container">
    <table class="sppd-table" v-if="items && items.length > 0">
      <thead>
        <tr>
          <td>ID</td>
          <td
            @click="changeOrder('nomor')"
            class="xlwide-column cursor-pointer"
          >
            <div class="flex-row sort__button">
              <v-icon v-if="lastOrderBy === 'nomor'"> {{ sortIcon }} </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Nomor SPPD</span>
            </div>
          </td>
          <td
            @click="changeOrder('nama_karyawan')"
            class="xlwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'nama_karyawan'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Nama Karyawan</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_jenis_perjalanan')"
            class="mdwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_jenis_perjalanan'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Jenis Perjalanan</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_tujuan_dinas_detail')"
            class="xlwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_tujuan_dinas_detail'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Tujuan Dinas</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_keperluan_dinas')"
            class="xlwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_keperluan_dinas'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Keperluan Dinas</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_tanggal_berangkat')"
            class="mdwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_tanggal_berangkat'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Tanggal Berangkat</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_tanggal_kembali')"
            class="mdwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_tanggal_kembali'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Tanggal Kembali</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_transport')"
            class="xlwide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_transport'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Transportasi Dinas</span>
            </div>
          </td>
          <td
            @click="changeOrder('sppd_transport_lokasi_dinas')"
            class="wide-column cursor-pointer"
          >
            <div class="flex-row">
              <v-icon v-if="lastOrderBy === 'sppd_transport_lokasi_dinas'">
                {{ sortIcon }}
              </v-icon>
              <v-icon v-else> {{ iconUpDown }} </v-icon>
              <span>Kendaraan Lokasi Dinas</span>
            </div>
          </td>
          <td class="xlwide-column">Berkas Pendukung</td>
          <td class="xlwide-column">PDF SPPD</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.row_number">
          <td>{{ item.row_number }}</td>
          <td>{{ item.nomor }}</td>
          <td>{{ item.nama_karyawan }}</td>
          <td>{{ item.sppd_jenis_perjalanan }}</td>
          <td>{{ item.sppd_tujuan_dinas_detail }}</td>
          <td>{{ item.sppd_keperluan_dinas }}</td>
          <td>{{ mydate(item.sppd_tanggal_berangkat,'ldate') }}</td>
          <td>{{ mydate(item.sppd_tanggal_kembali,'ldate') }}</td>
          <td>{{ item.sppd_transport }}</td>
          <td>{{ item.sppd_transport_lokasi_dinas }}</td>
          <td>
            <span
              title="Download File Lampiran"
              @click="
                downloadFile(
                  'lampiran',
                  item.id_hash,
                  item.attachment_id ? item.attachment_id : 0,
                  item.nama_file_lampiran,
                  item.file_type
                )
              "
              class="is__link"
              >{{ item.nama_file_lampiran }}</span
            >
          </td>
          <td>
            <span
              title="Download SPPD"
              @click="downloadFile('sppd', item.id_hash, 0, 0,'')"
              class="is__link"
              >{{ item.signed_pdf_filename }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div style="margin: 0 auto; padding: 1rem; text-align: center" v-if="!items">
      <p>{{$t('text.data is not available')}}</p>
    </div>
    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<style lang="css" scoped>
.p4 {
  padding: 0.7rem;
}

/* .result-table {
  width: 100%;
}
.result-table,
.result-table td,
.result-table th {
  border-collapse: collapse;
  border: 1px solid #8b8b8b;
}
.result-table td,
.result-table th {
  border-collapse: collapse;
  border: 1px solid #8b8b8b;
  padding: 0.3rem 0.6rem;
} */

.sppd-table-container {
  width: 100%;
  /* Sesuaikan dengan kebutuhan */
  overflow-x: auto;
  /* Membuat tabel bisa discroll secara horizontal */
  max-height: 300px;
}

.full--screen .sppd-table-container {
  max-height: calc(500px);
}

.sppd-table {
  width: auto;
  border-collapse: collapse;
  /* Hilangkan jarak antar border */
}

.sppd-table th,
.sppd-table td {
  padding: 12px;
  /* Padding dalam sel */
  text-align: left;
  /* Atur perataan teks */
  border: 1px solid #ddd;
  /* Border antara sel */
}

.sppd-table .xlwide-column {
  min-width: 300px;
}

.sppd-table .mdwide-column {
  min-width: 180px;
}

.sppd-table .wide-column {
  min-width: 220px;
}

.sppd-table thead {
  background-color: #f4f4f4;
  /* Background untuk header */
  position: sticky;
  top: 0;
  /* Agar header tetap terlihat saat scroll */
  z-index: 1;
  /* Pastikan header berada di atas baris data */
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: underline;
  color: #666666;
}

.sppd-table thead td {
  font-weight: 600;
}

.sppd-table thead .v-icon__svg {
  width: 16px;
  height: 16px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.theme--dark table {
  background-color: #444;
}

.is__link {
  color: rgb(54, 181, 185);
  cursor: pointer;
}

.is__link:hover {
  text-decoration: underline;
}
</style>
